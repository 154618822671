import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Link, useFetcher } from "@remix-run/react";
import type { Session, SupabaseClient } from "@supabase/supabase-js";
import { LuMenu, LuX } from "react-icons/lu";
import { dateToString, getMondayOfDate, getNextMonday } from "~/utils/dates";
import { LogoWithText } from "./Logo";

export const NavBar = ({ session, supabase }: { session?: Session; supabase?: SupabaseClient }) => {
  const fetcher = useFetcher();
  const thisWeek = dateToString(getMondayOfDate());
  const nextWeek = dateToString(getNextMonday());
  return (
    <div className="navbar bg-base-100">
      <div className="navbar-start">
        <div className="relative">
          <Menu>
            <MenuButton className="btn btn-ghost btn-circle focus:outline-none">
              {({ open }) =>
                open ? (
                  <LuX className="animate-in spin-in-180 duration-300" size={22} />
                ) : (
                  <LuMenu className="animate-in spin-in-180 duration-300" size={22} />
                )
              }
            </MenuButton>
            <MenuItems
              anchor="bottom start"
              className="menu z-[999] p-2 shadow-2xl drop-shadow-xl bg-base-100 rounded-box w-52 focus:outline-none"
            >
              {!session?.user ? (
                <>
                  <MenuItem as="li">
                    {({ close }) => (
                      <Link to="/login" onClick={close}>
                        Sign in/up
                      </Link>
                    )}
                  </MenuItem>
                </>
              ) : null}
              <MenuItem as="li">
                {({ close }) => (
                  <Link to={`/plan/${thisWeek}`} onClick={close}>
                    This week's menu
                  </Link>
                )}
              </MenuItem>
              <MenuItem as="li">
                {({ close }) => (
                  <Link to={`/plan/explore/${nextWeek}`} onClick={close}>
                    Plan next week
                  </Link>
                )}
              </MenuItem>
              <MenuItem as="li">
                {({ close }) => (
                  <Link to="/favorites" onClick={close}>
                    Favorites
                  </Link>
                )}
              </MenuItem>
              <MenuItem as="li">
                {({ close }) => (
                  <Link to="/save_for_later" onClick={close}>
                    Saved for later
                  </Link>
                )}
              </MenuItem>
              <MenuItem as="li">
                {({ close }) => (
                  <Link to="/about" onClick={close}>
                    About
                  </Link>
                )}
              </MenuItem>
              {session?.user && supabase ? (
                <>
                  <MenuItem as="li">
                    {({ close }) => (
                      <span
                        onClick={async () => {
                          await supabase?.auth.signOut();
                          fetcher.submit({}, { method: "post", action: "/signout" });
                        }}
                      >
                        Sign out
                      </span>
                    )}
                  </MenuItem>
                </>
              ) : null}
            </MenuItems>
          </Menu>
        </div>
      </div>
      <div className="navbar-center">
        <Link to="/" className="btn btn-ghost text-xl">
          <LogoWithText height={40} />
        </Link>
      </div>
      <div className="navbar-end"></div>
    </div>
  );
};
