import { H, HighlightInit } from "@highlight-run/remix/client";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import type { MetaFunction } from "@remix-run/react";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRevalidator,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, setUser, withSentry } from "@sentry/remix";
import type { Session, SupabaseClient } from "@supabase/auth-helpers-remix";
import { createBrowserClient } from "@supabase/auth-helpers-remix";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Footer } from "./components/Footer";
import { Logo } from "./components/Logo";
import { NavBar } from "./components/Navbar";
import "./tailwind.css";
import { getUserId } from "./utils/sessions.server";

export const meta: MetaFunction = () => [
  {
    charset: "utf-8",
    title: "FeedUs",
    viewport: "width=device-width,initial-scale=1",
  },
];

export const links: LinksFunction = () => [
  {
    rel: "apple-touch-icon",
    href: "/apple-touch.png",
  },
  {
    rel: "manifest",
    href: "/manifest.json",
  },
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { headers, supabaseSession, userId } = await getUserId({ request });
  const env = {
    SUPABASE_URL: process.env.SUPABASE_URL!,
    SUPABASE_PUBLIC_KEY: process.env.SUPABASE_PUBLIC_KEY!,
    HIGHLIGHT_PROJECT_ID: process.env.HIGHLIGHT_PROJECT_ID,
  };
  return json({ env, session: supabaseSession, userId }, { headers });
};

function App() {
  const { env, session, userId } = useLoaderData<typeof loader>();
  const { revalidate } = useRevalidator();

  const [supabase] = useState(() => createBrowserClient(env.SUPABASE_URL!, env.SUPABASE_PUBLIC_KEY!));

  const serverAccessToken = session?.access_token;

  useEffect(() => {
    if (userId) {
      setUser({ id: userId });
      H.identify(userId);
    }
  }, [userId]);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event !== "INITIAL_SESSION" && session?.access_token !== serverAccessToken) {
        // server and client are out of sync.
        revalidate();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [serverAccessToken, supabase, revalidate]);

  return (
    <Wrapper session={session as Session | undefined} supabase={supabase} highlightProjectId={env.HIGHLIGHT_PROJECT_ID}>
      <Outlet context={{ supabase, session }} />
    </Wrapper>
  );
}

export default withSentry(App);

function Wrapper({
  children,
  highlightProjectId,
  session,
  supabase,
}: {
  session?: Session;
  supabase?: SupabaseClient;
  children: React.ReactNode;
  highlightProjectId?: string;
}) {
  return (
    <html lang="en" data-theme="mainTheme" className="h-full">
      {highlightProjectId && (
        <HighlightInit
          projectId={highlightProjectId}
          serviceName="my-remix-frontend"
          tracingOrigins
          networkRecording={{ enabled: true, recordHeadersAndBody: true }}
        />
      )}
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="flex flex-col min-h-full">
        <ToastContainer position="bottom-right" />
        <NavBar session={session} supabase={supabase} />
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function ErrorMessage({ status, message }: { status: number; message: string }) {
  return (
    <>
      <h1 className="text-6xl font-bold text-primary mt-5">{status}</h1>
      <p className="text-lg mt-4">{message}</p>
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return (
    <Wrapper>
      <div className="flex flex-col flex-grow items-center mt-12">
        <Logo width={120} />
        {isRouteErrorResponse(error) ? (
          <ErrorMessage
            status={error.status}
            message={
              error.status === 404 ? "Oops! The page you're looking for is not found." : "Failed to load page :("
            }
          />
        ) : (
          <ErrorMessage status={500} message="Oh no! Internal error :(" />
        )}
        <Link to="/" className="mt-5 btn">
          Go Home
        </Link>
      </div>
      <Footer />
    </Wrapper>
  );
}
